import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prequisites`}</h2>
    <ul>
      <li parentName="ul">{`iOS 15+`}</li>
      <li parentName="ul">{`XCode 13+`}</li>
      <li parentName="ul">{`Swift 5.6+`}</li>
    </ul>
    <div className="divi" />
    <h2>{`Installation`}</h2>
    <h2>{`Step`}</h2>
    <ol>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "https://gitlab.playcourt.id/mobileteam/legion-ios"
        }}>{`https://gitlab.playcourt.id/mobileteam/legion-ios`}</a></li>
      <li parentName="ol">{`Copy URL Clone with SSH`}
        <div parentName="li" {...{
          "className": "line",
          "style": {
            "backgroundColor": "white"
          }
        }}>{`
  `}<div parentName="div" {...{
            "className": "item-one"
          }}>{`
    `}<div parentName="div" {...{
              "style": {
                "padding": "7px 0"
              }
            }}>{`
      `}<img parentName="div" {...{
                "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/development/install/installasi-1.png",
                "alt": "steps installation"
              }}></img>{`
    `}</div>{`
  `}</div>
        </div>
      </li>
      <li parentName="ol">{`Open XCode`}</li>
      <li parentName="ol">{`Open Package Dependencies`}
        <div parentName="li" {...{
          "className": "line",
          "style": {
            "backgroundColor": "white"
          }
        }}>{`
  `}<div parentName="div" {...{
            "className": "item-one"
          }}>{`
    `}<div parentName="div" {...{
              "style": {
                "padding": "7px 0"
              }
            }}>{`
      `}<img parentName="div" {...{
                "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/development/install/installasibaru3.png",
                "alt": "steps installation"
              }}></img>{`
    `}</div>{`
  `}</div>
        </div>
      </li>
      <li parentName="ol">{`Add search copy URL link Gitlab Clone`}
        <div parentName="li" {...{
          "className": "line",
          "style": {
            "backgroundColor": "white"
          }
        }}>{`
  `}<div parentName="div" {...{
            "className": "item-one"
          }}>{`
    `}<div parentName="div" {...{
              "style": {
                "padding": "7px 0"
              }
            }}>{`
      `}<img parentName="div" {...{
                "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/development/install/installasi-baru2.png",
                "alt": "steps installation"
              }}></img>{`
    `}</div>{`
  `}</div>
        </div>
      </li>
      <li parentName="ol">{`Add Theme`}
        <div parentName="li" {...{
          "className": "line",
          "style": {
            "backgroundColor": "white"
          }
        }}>{`
  `}<div parentName="div" {...{
            "className": "item-one"
          }}>{`
    `}<div parentName="div" {...{
              "style": {
                "padding": "7px 0"
              }
            }}>{`
      `}<img parentName="div" {...{
                "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/development/install/installasi-baru1.png",
                "alt": "steps installation"
              }}></img>{`
    `}</div>{`
  `}</div>
        </div>
      </li>
      <li parentName="ol">{`Legion is Ready to Use !`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Theme`}</h2>
    <p>{`Managing Theme of the Package`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click General → Target`}</p>
        <div parentName="li" {...{
          "className": "line",
          "style": {
            "backgroundColor": "white"
          }
        }}>{`
  `}<div parentName="div" {...{
            "className": "item-one"
          }}>{`
    `}<div parentName="div" {...{
              "style": {
                "padding": "7px 0"
              }
            }}>{`
      `}<img parentName="div" {...{
                "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/development/install/installasi-baru-4.png",
                "alt": "theme"
              }}></img>{`
    `}</div>{`
  `}</div>
        </div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Scroll Down -> Manage Theme`}</p>
        <div parentName="li" {...{
          "className": "line",
          "style": {
            "backgroundColor": "white"
          }
        }}>{`
  `}<div parentName="div" {...{
            "className": "item-one"
          }}>{`
    `}<div parentName="div" {...{
              "style": {
                "padding": "7px 0"
              }
            }}>{`
      `}<img parentName="div" {...{
                "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/development/install/installasi-baru-5.png",
                "alt": "theme"
              }}></img>{`
    `}</div>{`
  `}</div>
        </div>
        <h3 parentName="li">{`Setup`}</h3>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add Theme to Target → General → Frameworks, Libraries, and Embedded Content`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`in AppDelegate.swift:`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Import selected theme`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`call LGN.configure()
`}</code></pre>
    <div className="divi" />
    <h2>{`Instant Handoff`}</h2>
    <p>{`Legion also support project creation based on Figma Design. We called this project “Instant Handoff”, for example you can watch this video `}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/DdfgR0wE3wc",
        "title": "Instant Handoff Example",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`Requirements`}</h3>
    <ol>
      <li parentName="ol">{`Install `}<a parentName="li" {...{
          "href": "https://www.python.org/ftp/python/3.12.0/python-3.12.0-macos11.pkg"
        }}>{`python3`}</a></li>
      <li parentName="ol">{`Install `}<a parentName="li" {...{
          "href": "https://github.com/yonaskolb/XcodeGen"
        }}>{`Xcodegen`}</a></li>
      <li parentName="ol">{`Install `}<a parentName="li" {...{
          "href": "https://docs.fastlane.tools/getting-started/ios/setup/"
        }}>{`Fastlane`}</a></li>
      <li parentName="ol">{`Install `}<a parentName="li" {...{
          "href": "https://formulae.brew.sh/formula/ios-sim"
        }}>{`ios-sim`}</a></li>
      <li parentName="ol">{`Install `}<a parentName="li" {...{
          "href": "https://github.com/apple/swift-format"
        }}>{`swift-format`}</a></li>
      <li parentName="ol">{`XCode 14.x.x or latest`}</li>
      <li parentName="ol">{`Install `}<a parentName="li" {...{
          "href": "https://github.com/yonaskolb/XcodeGen"
        }}>{`Xcodegen`}</a></li>
    </ol>
    <h3>{`Getting started`}</h3>
    <ol>
      <li parentName="ol">{`Clone repository: `}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`git clone https://gitlab.playcourt.id/telkomdev-fardan/ios-legion-handoff.git
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Select the repository directory in Terminal.`}</li>
      <li parentName="ol">{`Run from your terminal.`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`bundle exec fastlane generate
`}</code></pre>
    <h3>{`Notes`}</h3>
    <h4>{`Make Sure Your Ruby Version is Supported`}</h4>
    <p>{`If you are using a MacBook, it comes with a built-in Ruby located in `}<inlineCode parentName="p">{`/usr/bin`}</inlineCode>{`. However, you should install your own Ruby version. Follow the detailed steps provided on `}{`[this page]`}{` (`}<a parentName="p" {...{
        "href": "https://gorails.com/setup/macos/13-ventura"
      }}>{`https://gorails.com/setup/macos/13-ventura`}</a>{`) to install Ruby on your device. Make sure Ruby is installed successfully by referring to that guide.`}</p>
    <h4>{`Make Sure Your `}<inlineCode parentName="h4">{`bundler`}</inlineCode>{` Version is Supported`}</h4>
    <p>{`After installing the required Ruby version, install Bundler, which is needed for Fastlane. This repository uses Bundler version 2.4.3. Install Bundler using:`}</p>
    <pre><code parentName="pre" {...{}}>{`gem install bundler
`}</code></pre>
    <p>{`If you encounter permission errors, avoid using sudo unless absolutely necessary. Instead, install Bundler locally:`}</p>
    <pre><code parentName="pre" {...{}}>{`gem install bundler --user-install
`}</code></pre>
    <p>{`This method is safer than using sudo.`}</p>
    <h4>{`Make Sure Your `}<inlineCode parentName="h4">{`gem`}</inlineCode>{` Version is Supported`}</h4>
    <p>{`Ensure your gem version is compatible. This repository requires gem version 3.3.26 or higher. Check your gem version with:`}</p>
    <pre><code parentName="pre" {...{}}>{`gem --version
`}</code></pre>
    <p>{`If your gem version is supported, you can build the project using:`}</p>
    <pre><code parentName="pre" {...{}}>{`bundle exec fastlane generate
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      